 
// Body
$body-bg: #F8F7F5;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #337ab7;
$brand-info: #5bc0de;
$brand-success: #5cb85c;
$brand-warning: #FFBB00; 
$brand-danger: #d9534f;

$gray-base:              #2e2e2e !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

// Typography

$font-family-sans-serif: "Swiss721W01-LightExtend", sans-serif;
$font-family-serif: 'NewCenturySchoolW01-Rg';
$font-family-base: $font-family-serif;
$font-size-base: 15px;
$line-height-base: 1.6;
$text-color: #2e2e2e;


$headings-font-family: 'Swiss721W01-BoldExtende';
$headings-color: $brand-primary;


// Navbar
$navbar-default-bg: #fff;
$navbar-default-toggle-border-color: $laravel-border-color;
$navbar-default-toggle-hover-bg: $body-bg;
$navbar-default-toggle-icon-bar-bg: $laravel-border-color;


// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: darken($body-bg, 15%);
$input-border-focus: darken($body-bg, 25%);
$input-color-placeholder: lighten($text-color, 30%);
$input-color: $gray-dark;
$input-color-placeholder: $gray-light;

// Panels
$panel-default-heading-bg: #fff;

// Wells
$well-bg : #FFFFFF;
$well-border : #FFFFFF;

//Carousel
$carousel-indicator-border-color: $gray;
$carousel-indicator-active-bg: $gray;

$screen-xs-max: 720px;
$screen-sm-min: 721px;
$screen-sm-max: 940px;