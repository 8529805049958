
.band__name--link, .gig__name--link {
	@include link--block;
}

.band__name--displayname {
	text-transform: none !important;
}

.bandlist__block {
	clear: both;
	margin-top: 2em;
	overflow: auto;
}

.bandlist {

	.band {
		min-height: 100px;
		margin-top: 1em;

		a { display: block; }

		a.media > .pull-left { 
			margin-right: 1.25em; padding: 0; width: 33%; 
			@media (max-width: $screen-xs-max) { width: 115px; }
		}

		.media-body { margin-top: 2em; }

		.band__name { @include link--block; margin-right: 0; position: absolute; bottom: 2em; left:2.5em; }
		
		.band__name--link { margin-top: 1.25em; }

		.band__img__frame { background-color: $gray-light; border-radius: 1em; }

		.band__img {
			width: 100%;
			background: $gray-light;
			border-radius: 1em;
			background-position: top;
			background-origin: border-box;
			background-size: cover;
			margin-bottom: 1em;
			text-align: left;

			@media (max-width: $screen-xs-max) { height: 145px; }
			@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { height: 155px; }
			@media (min-width: $screen-md-min) and (max-width: $screen-md-max) { height: 150px; }
			@media (min-width: $screen-lg-min) { height: 185px; }
		}
	}
}

.list-initials { margin: 0; }
.list-initials > li {
	padding: 0;
	a { @include link--block; }
}

.band__initial {
	@include link--block;
	position: absolute;
	margin-left: -3em;
}

.bandlinks {
	display: inline-block;
	i { color: $gray; }
	li { padding-right: .75em; font-size: 1.75em; }
}

.bio__nav {
	margin-left: 1em;
	display: inline-block;
	a {
		font-family: $font-family-sans-serif;
		text-transform: uppercase;
		color: $gray;
		font-size: .75em;
	}
}