h1 {
	margin-top: .4em;
	margin-bottom: 2.25em;
	color: $brand-primary;
	text-transform: uppercase;
	font-size: 1.35em;
} 

h2, h3, h4 {
	color: $brand-primary;
	text-transform: uppercase;
}

h2 {
	font-size: 1.35em;
	margin-bottom: 1.5em;
}

h3 {
	font-size: .75em;
}

h4 {
	font-size: .65em;
}

.text-default { color: $text-color; }