// layouts/layout


body {
	background-image: url(../img/background-body.jpg);
	background-repeat: repeat;
	background-position: top left;
}

#main { 
	padding-top: 2em;
}

#redsquare {
	background-color: #F16521;
	border-radius: 12px;
	width: 77px;
	height: 64px;
	position: absolute;
	margin: -55px 0 0 -40px;
}

#turquoisesquare {
	background-color: #87C2C7;
	border-radius: 8px;
	width: 41px;
	height: 34px;
	position: absolute;
	margin: 100px 0 0 -90px;
	display: none;
}

// Fix position for nav on ml, lg and xl
// @media (min-width: $screen-sm-max) {
// 	#header {
// 		position: fixed;
// 		top: 0;
// 		right: 0;
// 		left: 0;
// 		z-index: 1030;
// 	}
// 	#main {
// 		margin-top: 7.5em;
// 	}
// }

#header {
	background-image: url(../img/background-top.jpg);
	background-repeat: repeat-x;
	background-position: top left;
	box-shadow: 0px 0px 56px 0px rgba(0,0,0,.1);

	

	.navbar-toggle {
		margin-top: 3.5em;
		border: 1px solid $laravel-border-color; 
		.icon-bar {
			background-color: $laravel-border-color;
		}
	}

	.navbar {
		margin-bottom: 0;
	}




	.navbar-brand {
		width: 350px;
		padding: 1em 0;
		display: inline-block;
		float: left;
		height: auto;

		@media (max-width: $screen-xs-max) {

			width: 230px;

			.media-left {
				display: none;
			}
		}

		.media-left {
			padding-right: 1.25em;
		}

		img {
			margin-top: 14px;
			width: 199px;
		}

		.baseline {
			width: 150px;
			margin-top: 8px;
		}
		
	}

	.nav {
		margin-top: 1.75em;

		li {
			text-align: left;
			font-family:'Swiss721W01-LightExtend';
			text-transform: uppercase;
			
			&:first-child {
				border-top: 0;
			}

			&:last-child a {
				margin-right: 0;
			}

			a { 
				color: $text-color; 
				font-size: .85em;
				display: inline-block;
				padding: 1.5em .5em;
				margin: 0 2em 0 0;

				&:hover {
					text-decoration: none;
					color: $brand-primary;
				}
			}
		}

	}
// Header SM
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
	.logo {
		width: 70px !important;
	}

	.navbar-brand img {
		margin-top: 7px;
		width: 190px;
	}
}


	//	Mobile menu
	@media (max-width: $screen-xs-max) {
		background: white;
		.nav { margin-top: 0; }
		li { border-top: 1px dotted $laravel-border-color; }
		.navbar-brand img { width: 180px; }
	}
}


footer {
	margin: 3em 0 1.5em;
	padding-top: 2em;
	border-top: 1px dotted darken($body-bg, 10%);

	.copyright { height: 26px; }
	.link-social { display: inline-block; margin-top: .25em; }

	img { margin-right: 3em; }

	li { vertical-align: middle; }

	.list-inline [class^="icon-"] { 
		color: #969594; 
		font-size: 1.25em;
	}

	.nav li {
		text-align: center;
		font-family:'Swiss721W01-LightExtend';
		text-transform: uppercase;
		a { 
			color: $text-color; 
			display: inline-block;
			padding: .5em .5em;
			margin: 0 2em;
			font-size: .85em;

			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}

	a:hover { text-decoration: none; }

	@media (max-width: $screen-xs-max) {
		.list-inline>li:nth-child(2), .list-inline>li:nth-child(3) {
			display: none;
		}

		.navbar-nav {
			margin-top: 2em;
			clear: both;
			float: none;
			li { text-align: left; }
		}
	}
}

.nav > li > a:hover, .nav > li > a:focus {
	background-color: transparent;
}