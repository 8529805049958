@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?lhn4gp');
  src:  url('../fonts/icomoon.eot?lhn4gp#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?lhn4gp') format('truetype'),
    url('../fonts/icomoon.woff?lhn4gp') format('woff'),
    url('../fonts/icomoon.svg?lhn4gp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
 
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-more_horiz:before {
  content: "\e900";
}
.icon-asterisk:before {
  content: "\f069";
}
.icon-plus:before {
  content: "\f067";
}
.icon-search:before {
  content: "\f002";
}
.icon-star:before {
  content: "\f005";
}
.icon-star-o:before {
  content: "\f006";
}
.icon-check:before {
  content: "\f00c";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-trash-o:before {
  content: "\f014";
}
.icon-plus-circle:before {
  content: "\f055";
}
.icon-arrow-right:before {
  content: "\f061";
}
.icon-eye:before {
  content: "\f06e";
}
.icon-eye-slash:before {
  content: "\f070";
}
.icon-comment:before {
  content: "\f075";
}
.icon-twitter-square:before {
  content: "\f081";
}
.icon-facebook-square:before {
  content: "\f082";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-facebook-f:before {
  content: "\f09a";
}
.icon-globe:before {
  content: "\f0ac";
}
.icon-chain:before {
  content: "\f0c1";
}
.icon-link:before {
  content: "\f0c1";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-ellipsis-h:before {
  content: "\f141";
}
.icon-external-link-square:before {
  content: "\f14c";
}
.icon-skype:before {
  content: "\f17e";
}
.icon-spotify:before {
  content: "\f1bc";
}
.icon-soundcloud:before {
  content: "\f1be";
}
.icon-trash:before {
  content: "\f1f8";
}
.icon-window-restore:before {
  content: "\f2d2";
}

