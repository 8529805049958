@mixin link--block {
	display: inline-block;
	font-size: .8em;
	display: inline-block;
	padding: .25em .5em .15em;
	background-color: #e0e0e0;
	color: $text-color;
	text-transform: uppercase;
	border-radius: .55em;
	margin: 0;
	font-family: $headings-font-family;
	transition: all 0.2s ease-out;

	&:hover {
    	text-decoration: none;
	}	
}

@mixin date {
	// font-family: $font-family-sans-serif;
	text-transform: uppercase;
	color: lighten($text-color, 40%);
	font-size: .7em;
	display: inline-block;
	margin-right: .75em;
}