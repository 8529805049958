// Variables
@import "_base/_variables";

// Bootstrap overrides
$brand-primary: #428993;
$brand-info: #87C2C7;
$brand-success: #2ab27b;
$brand-warning: #FFBB00;
$brand-danger: #F16521;

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "_vendor/bootstrap-select";
@import "_base/_mixins";
@import "_base/_typography";
@import "_layouts/_layout";
@import "_modules/_gig";
@import "_modules/_band";
@import "_no-modular/_pages";
@import "_no-modular/_icons";

a, a:hover, a:active, a:focus { text-decoration: none; }

.list-social li i { font-size: 2em; }

.bio__nav li.active { display: none; }

.img-rounded { border-radius: 16px; }


.thumb-captioned-rolloverover {

	background-color: $brand-danger;

	.thumb-captioned__caption { 
		opacity: 0;
	}

	&:hover {
		.thumb-captioned__caption { 
			opacity: 1;
		}
		img {
			opacity: .4;
			filter: saturate(0);
		}
	}
}

.thumb-captioned {
	position:relative;
	overflow:hidden;
	border-radius: 14px;
	display: block;
	margin-bottom: .5em;



	&__caption {
		position:absolute;
		bottom:2%;
		width:100%;
		padding:5%;
		text-align:center;
		color:#fff !important;
		z-index:2;
		h2 { color: white; }
	}
}

img.hr {
	margin: 1.5em 0;
}

// Labels 

.label {
	text-transform: uppercase;
	font-family:'Swiss721W01-LightExtend';
	font-size: .4em;
	padding: .3em .75em .375em 1em;
	letter-spacing: .15em;
	border-radius: 4px;
	vertical-align: 2px;
}
.label-new { @extend .label-danger; }
.label-lasttickets { @extend .label-warning; }
.label-soldout { @extend .label-primary; }
.label-cancelled { @extend .label-default; }
.label-corporate { @extend .label-warning; }


@media print {
	a[href]:after {
		content: "";
	}

	ul.list-inline { display: none; }
}

a:hover .band__name--link, a:hover .gig__name { background-color: lighten($gray-light, 12%) !important; }
a:hover .hover0, a.hover0:hover { background-color: $brand-primary !important; color: #fff; }
a:hover .hover1, a.hover1:hover { background-color: $brand-danger !important; color: #fff; }
a:hover .hover2, a.hover2:hover { background-color: $text-color !important; color: #e0e0e0; }

.color0 { background-color: $brand-primary !important;  color: #fff; }
.color1 { background-color: $brand-danger !important; color: #fff; }
.color2 { background-color: $text-color !important; color: #e0e0e0; }
