// Pages 

// Contact page

.body--contact {
	.thumb-captioned__caption {
		padding-left: 2em !important;
		p { text-align: left; clear: both; } 
		img { margin-bottom: .5em; height: 65px; float: left; clear: both; }
		a { color: white; }	
	}

	h3 { margin: 2.75em 0 3em; }
	.tit-tel, .tit-fax { height: 1em; margin-right: .25em; vertical-align: -1px; }
}

// Homepage 


.body--homepage {

	.spotify {		
		
		.embed-responsive { height: 450px; }
	}

	@media (max-width: $screen-sm-max) {
		.gigs-feed__title, .news__title {
			margin-top: 2.5em;
		}
	}

	#tit_playlist {
		max-width:480px;

		@media (max-width: $screen-xs-max) {
			max-width: 100%;
		}
	}

	#tit_news {
		@media (max-width: $screen-xs-max) {
			display: none;
		}	
	}
	
	.statsholder { 
		background: url(../img/background-bands.jpg); 
		background-position: 0;
		background-origin: border-box;
		background-repeat: no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		margin: 3em 0;
		position: relative;
		width: 100%;
		min-height: 300px;
	}

	.stats {
		margin: 3.75em 0 0;

		@media (max-width: $screen-xs-max) {
			margin-top: 1em;
			padding-bottom: 2.5em;
		}
		.stats-number  {
			font-size:5.5em;
			margin-bottom: 0;
			padding-bottom: 0;
			line-height: 1.25em;
			img {
				margin-right: -13px;
				height: 75px; 
			}
		}
		.stats-label {
			text-transform: uppercase;
			color: white;
			font-family: $headings-font-family;
			vertical-align: bottom;
		}
	}

}


.body--gigsbyband, .body--gigsbymonth, .body--gigsbynew {
	@media (max-width: $screen-xs-max) { 
		.selectpicker {
			width: 100%;
			margin-bottom: 3em;
		}
	}
}

// Concerts by background: 

.body--gigsbyband {

	.band {
		margin-bottom: .25em;
	}

	.initial {
		display: block;
		margin-bottom: 1em; 
	}

	.band:last-child {
		margin-bottom: 0;
	}
}

.body--band {
	h1 { 
		margin-right: .5em;
		margin-bottom: 1.75em;
		color: $text-color;
		font-size: 2em;
		display: inline-block;
	}

	.next {
		display: block;
		background: rgba(66, 137, 147, 1);
		background-position: center;
		background-origin: border-box;
		background-size: cover;
		text-align: center;
		margin-top: 3em;
		width: 100%;
		height: 600px;
		position: relative;
		overflow: hidden;
		transition: all 0.3s ease-out;
		@media (max-width: $screen-xs-max) { 
			height: 300px;
		}

		&:hover {
			img {
				opacity: 1;
				-webkit-filter: grayscale(0);
				filter: grayscale(0);
			}
		}
		
		h3 { color: white; margin-top: 0; }
		p { color: white; font-family: $headings-font-family; font-size:3em; text-transform: uppercase; font-weight: 500;
			@media (max-width: $screen-xs-max) {
				font-size: 2em;
			}
		}

		img {
			transition: all 0.3s ease-out;
			opacity: .6;
			-webkit-filter: grayscale(1);
			filter: grayscale(1);
			width: 100%;
		}

		&__caption {
			margin-top: 16em;
			position: absolute;
			width: 100%;
			z-index: 999;

			@media (max-width: $screen-xs-max) { 
				margin-top: 5em;
			}
		}

	}

	@media (min-width: 721px) {
		.carousel-indicators {
			bottom: -40px;
		}
	}

	.carousel {
		position: static;

		.thumb-captioned__caption img.title { width:60px; margin-top: 2em;}
		.thumb-captioned__caption, h2 {
			color: white;
			font-family: $headings-font-family;
		}
		h2 { 
			font-size: 2.5em; 
			@media (max-width: $screen-xs-max) { 
				font-size: 1.5em;
			}
		}

		@media (max-width: $screen-xs-max) { 
			.caption-copy {
				bottom: 1em !important;
				left: 1em !important;
			}
		}
	}
	.carousel-control {
		position: absolute;
		top: 500px;
		bottom: auto;
		opacity: 1;
		background: none !important;
		width: 5%;

		img {
			height: 3em;
		}
	}


	.videos {
		margin-bottom: 2em;
		img { width: 100%; }

		.item:nth-child(n) .thumb-captioned__caption {
			background:rgba(241, 101, 34, 0.58);
		}
		.item:nth-child(2n) .thumb-captioned__caption {
			background:rgba(66, 137, 147, 0.58);
		}

		.thumb-captioned__caption {
			height: 100%;
			top:0;
			bottom: 0;
		}

		.caption-copy {
			bottom: 2.5em;
			left: 2.5em;
			text-align: left;
			position: absolute;

			h2 { margin-bottom: 0; }
		}

		@media (max-width: $screen-xs-max) { 
			.btn-play { margin-top: 50px; }
		}

		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
			.btn-play { margin-top: 66px; }
		}
	}

	.photos {
		
		margin-top: 3em;
		
		.title { margin-bottom: 1em; }

		.caption {
			padding-top: 3em;
			opacity: 0;
			&:hover { 
				background:rgba(135, 194, 199, 0.88); 
				opacity: 1;
			}
		}
		
		i { font-size:2em; }
		
	}

	.video iframe { border-radius: 14px; }

	.btn-play {
		text-transform: uppercase;
		font-family: $headings-font-family;
		color: white;
		vertical-align: center;
		height: 100%;
		width: 100%;
		display: block;
		display: inline-block;
		&:hover { color: white; }
		img { width: 85px;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			left: 50%;
			margin-left: -41px;
		}

	}
}