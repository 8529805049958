// Gigs

li a.gig {
	margin-bottom: 0;
}

.gig {

	transition: all 0.2s ease-out;
	display: inline-block;
    margin-top: .5em 0 0 0;

	.media-body { padding-top: 0; }
	.media-object { padding-right: .25em; }

	i.icon {
		opacity: 0;
		color: $gray-light;
		vertical-align: -1px;
		font-size: .85em;
		transition: all 0.2s ease-out;
	}
	&:hover i.icon { opacity: 1; }

	&__name {
		@include link--block;
		margin-right: .75em;
	}

	&__date {
		@include date;
		min-width: 3.5em;
	}

	&__venue {
		color: $text-color;
	}

	&__extra {
		color: lighten($text-color, 40%);
		white-space: nowrap;
		display: inline-block;
    	margin-bottom: .25em;
    	margin-left: .5em;
	}
}

.giglist {

	margin-bottom: 0;
	
	li img {
		margin: .35em 0;
	}

	li:last-child {
		img {
			display: none;
		}

		.gig { margin-bottom: 0; }
	}

	&--month li.first {
		margin-top: 1em;
	}
}

.gigmenu {
	margin-top: 1em;
	vertical-align: middle;
}


h2.month {
	margin: 0;
	img { height: 50px; }
}